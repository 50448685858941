<template>
    <div>
        <weacg-loading v-if="showLoading"></weacg-loading>
        <div class="loader">
            <p>{{ title }}</p>
            <div class="cas">
                <canvas id="loader"></canvas>
            </div>
            <div class="input-area">
                <span>
                    <input class="swing" type="password" placeholder="Code" @change="login(1)"
                        v-model="pwd.princess" /><label for="eyes">回家看看</label>
                </span>
                <span>
                    <input class="swing" type="password" placeholder="Code" @change="login(2)"
                        v-model="pwd.visitor" /><label for="eyes">来访通道</label>
                </span>
            </div>
            <div class="gov">
                <a class="icp" href="https://beian.miit.gov.cn/">冀ICP备2021012617号-2</a>
                <div class="ga">
                    <img class="icon" src="@/assets/imgs/ba.png" />
                    <a
                        href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=13062602000107">冀公网安备13062602000107号</a>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import JsEncrypt from 'jsencrypt'
import { publicKey } from '@/utils/index.js'
import {
    requestLogin,
    getUserByToken,
    UnableCustomPwd,
    HasYearMessage
} from '@/api/api'

export default {
    data() {
        return {
            title: '欢迎',
            text: ['别来无恙', '你在心上', '欢迎回家', '休息一下', '云无心以出岫', '曾经沧海难为水', '前途似海', '来日方长', '愿得一心人', '同声若鼓瑟', '寒江陪烟火', '不负时光不负卿', '此生绵长和', '双眸秋水透诗行', '一念灵犀未敢忘', '行笔至半  纸短情长', '相离莫相忘'],
            pwd: {
                princess: null,
                visitor: null
            },
            info: {
                userid: null,
                pwd: null
            },
            showLoading: false
        }
    },
    created() {
        this.title = this.text[this.getRandomInt(17)]
        localStorage.removeItem("store");
        window.customStore = null
    },
    mounted() {
        this.Loadr('loader');
    },
    methods: {
        login(type) {
            switch (type) {
                case 1:
                    setTimeout(() => {
                        if (this.pwd.princess) {
                            this.initLogin(1);
                        } else {
                            alert('请输入访问密钥');
                        }

                    }, 1000)

                    break;
                case 2:
                    setTimeout(() => {
                        if (this.pwd.visitor) {
                            this.initLogin(2);
                        } else {
                            alert('请输入访问密钥');
                        }
                    }, 1000)
                    break;
                default:
                    break;
            }
        },
        async initLogin(type) {
            this.showLoading = true;
            let _this = this
            let EncryptPwd;
            if (type === 1) {
                this.info.userid = 'admin'
                EncryptPwd = this.pwd.princess;
            } else {
                this.info.userid = 'visit'
                EncryptPwd = this.pwd.visitor;
            }

            //rsa加密密码
            let rsa = new JsEncrypt();
            rsa.setPublicKey(publicKey);
            let rsa_p = rsa.encrypt(EncryptPwd);
            this.info.pwd = rsa_p;

            let loginParams = {
                name: this.info.userid,
                pass: this.info.pwd
            }

            requestLogin(loginParams).then(data => {
                if (!data.success) {
                    _this.showLoading = false;
                    _this.$notify.error({
                        title: '错误',
                        message: '登录失败，请检查密钥',
                        duration: 3000
                    })
                    // _this.closeAlert()
                } else {
                    var token = data.response.token
                    console.log(token)
                    _this.$store.commit('saveToken', token)

                    var curTime = new Date()
                    var expiredate = new Date(
                        curTime.setSeconds(
                            curTime.getSeconds() + data.response.expires_in
                        )
                    )
                    _this.$store.commit('saveTokenExpire', expiredate)

                    window.localStorage.refreshtime = expiredate
                    window.localStorage.expires_in =
                        data.response.expires_in

                    //设置用户类型
                    _this.$store.commit('setUserType', _this.info.userid === 'admin' ? false : true)

                    if (_this.info.userid === 'visit') {
                        UnableCustomPwd({ password: this.info.pwd })
                            .then(() => {
                                _this.showLoading = false;
                                _this.$notify.info({
                                    type: 'success',
                                    message: '登陆成功，请稍后...',
                                    duration: 3000
                                })
                                _this.getUserInfoByToken(token)
                            })
                            .catch(() => {
                                _this.$notify.error({
                                    title: '错误',
                                    message: '登录失败，请检查网络',
                                    duration: 3000
                                })
                            })
                    } else {
                        _this.showLoading = false;
                        _this.$notify.info({
                            type: 'success',
                            message: '登陆成功，请稍后...',
                            duration: 3000
                        })
                        _this.getUserInfoByToken(token)
                    }
                }
            })
                .catch(() => {
                    _this.showLoading = false;
                    _this.$notify.error({
                        title: '错误',
                        message: '网络出现问题',
                        duration: 3000
                    })
                })
                .finally(() => {
                    this.info = {
                        userid: null,
                        pwd: null
                    }
                })
        },
        getUserInfoByToken(token) {
            var _this = this
            getUserByToken(token).then(data => {
                if (!data.success) {
                    _this.openAlert(data.msg)
                } else {
                    setTimeout(() => {
                        if (_this.$store.state.isCustom) {
                            _this.$router.replace('/birthday')
                        } else {
                            HasYearMessage().then(res => {
                                if (res.data) {
                                    _this.$router.replace('/welcome')
                                } else {
                                    _this.$router.replace('/birthday')
                                }
                            })
                        }
                    }, 1000)
                }
            })
        },
        getRandomInt(max) {
            return Math.floor(Math.random() * max);
        },
        Loadr(id) {

            let ele = document.getElementById('loader');
            let size = window.innerWidth > window.innerHeight ? window.innerHeight : window.innerWidth
            ele.width = size * 0.5;
            ele.height = size * 0.5;

            // # 定义变量
            const max_size = size < 300 ? 10 : 24;
            const max_particles = size < 300 ? 750 : 1500;
            const min_vel = 20;
            const max_generation_per_frame = 10;

            // #初始化变量
            var canvas = document.getElementById(id);
            var ctx = canvas.getContext('2d');
            var height = canvas.height;
            var center_y = height / 2;
            var width = canvas.width;
            var center_x = width / 2;
            var animate = true;
            var particles = [];
            var last = Date.now(), now = 0;
            var died = 0, len = 0, dt;

            // 判断心形范围
            function isInsideHeart(x, y) {
                x = ((x - center_x) / (center_x)) * 3;
                y = ((y - center_y) / (center_y)) * -3;

                var x2 = x * x;
                var y2 = y * y;

                return (Math.pow((x2 + y2 - 1), 3) - (x2 * (y2 * y)) < 0);

            }

            function random(size, freq) {
                var val = 0;
                var iter = freq;

                do {
                    size /= iter;
                    iter += freq;
                    val += size * Math.random();
                } while (size >= 1);

                return val;
            }

            function Particle() {
                var x = center_x;
                var y = center_y;
                var size = ~~random(max_size, 2.4);
                var x_vel = ((max_size + min_vel) - size) / 2 - (Math.random() * ((max_size + min_vel) - size));
                var y_vel = ((max_size + min_vel) - size) / 2 - (Math.random() * ((max_size + min_vel) - size));
                var nx = x;
                var ny = y;
                var r, g, b, a = 0.05 * size;

                this.draw = function () {
                    r = ~~(255 * (x / width));
                    g = ~~(255 * (1 - (y / height)));
                    b = ~~(255 - r);
                    ctx.fillStyle = 'rgba(' + r + ',' + g + ',' + b + ',' + a + ')';
                    ctx.beginPath();
                    ctx.arc(x, y, size, 0, Math.PI * 2, true);
                    ctx.closePath();
                    ctx.fill();
                }

                this.move = function (dt) {

                    nx += x_vel * dt;
                    ny += y_vel * dt;
                    if (!isInsideHeart(nx, ny)) {
                        if (!isInsideHeart(nx, y)) {
                            x_vel *= -1;
                            return;
                        }

                        if (!isInsideHeart(x, ny)) {
                            y_vel *= -1;
                            return;
                        }
                        // Lets do the crazy furbidden
                        x_vel = -1 * y_vel;
                        y_vel = -1 * x_vel;
                        return;
                    }

                    x = nx;
                    y = ny;
                }

            }

            function movementTick() {
                var len = particles.length;
                var dead = max_particles - len;
                for (var i = 0; i < dead && i < max_generation_per_frame; i++) {
                    particles.push(new Particle());
                }

                // Update the date
                now = Date.now();
                dt = last - now;
                dt /= 1000;
                last = now;
                particles.forEach(function (p) {
                    p.move(dt);
                });
            }

            function tick() {

                ctx.clearRect(0, 0, width, height);
                particles.forEach(function (p) {
                    p.draw();
                });

                requestAnimationFrame(tick);
            }

            this.start = function () {

            }

            this.done = function () {
                clearInterval(timer);
            }

            let timer = setInterval(movementTick, 16);
            tick();

        }
    }
}
</script>

<style lang="scss" scoped>
.loader {
    position: relative;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;

    .cas {
        width: 50vmin;
        height: 50vmin;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 8px;
    }

    canvas {
        transform: scale(1.8);
    }
}

.loader p {
    // position: absolute;
    font-family: "FZSJ-TGYBXSJW", "Oswald", sans-serif;
    margin-top: 10vh;
    text-align: center;
    width: 100%;
    line-height: 10vmin;
    font-size: 10vmin;
    text-shadow: 5px 5px 5px #a1a1a1;
    color: #e98787;
    font-weight: 400;
}

input {
    -webkit-user-select: text !important;
}

.content {
    width: 100vw;
    height: 100vh;
    position: absolute;
    // display: flex;
    // flex-direction: column;
    // justify-content: space-between;
    // align-items: center;
}

.swing {
    display: inline-block;
    width: 40vw;
    max-width: 255px;
    padding: 0px 0 0px 40px;
    height: 35px;
    font-family: "Open Sans", sans;
    font-weight: 400;
    color: #377D6A;
    background: #cfcece;
    border: 0;
    border-radius: 3px;
    outline: 0;
    text-indent: 60px; // Arbitrary.
    transition: all .3s ease-in-out;

    &::-webkit-input-placeholder {
        color: #efefef;
        text-indent: 0;
        font-weight: 300;
    }

    +label {
        display: inline-block;
        position: absolute;
        top: 0;
        left: 0;
        color: white;
        padding: 0px 15px;
        height: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-shadow: 0 1px 0 rgba(19, 74, 70, .4);
        background: #e98787;
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
        transform-origin: 2px 2px;
        transform: rotate(0);
        // There should be a better way
        animation: swing-back .4s 1 ease-in-out;
    }
}

@keyframes swing {
    0% {
        transform: rotate(0);
    }

    20% {
        transform: rotate(116deg);
    }

    40% {
        transform: rotate(60deg);
    }

    60% {
        transform: rotate(98deg);
    }

    80% {
        transform: rotate(76deg);
    }

    100% {
        transform: rotate(82deg);
    }
}

@keyframes swing-back {
    0% {
        transform: rotate(82deg);
    }

    100% {
        transform: rotate(0);
    }
}

span {
    position: relative;
    display: inline-block;
    margin: 25px 10px;
}

.swing:focus,
.swing:active {
    color: #377D6A;
    text-indent: 0;
    background: #fff;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;

    &::-webkit-input-placeholder {
        color: #aaa;
    }

    +label {
        animation: swing 1.4s 1 ease-in-out;
        transform: rotate(82deg);
    }
}


.input-area {
    // position: absolute;
    width: 100%;
    height: 180px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    transform: translateY(-5vh);
}

.gov {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    a {
        color: gray;
    }

    .ga {
        margin-top: 10px;
        display: flex;
        justify-content: center;

        img {
            display: block;
            margin-right: 5px;
        }
    }

    width: 100%;
    text-align: center;
    margin: 0 auto;
    padding-bottom: 10px;
}
</style>